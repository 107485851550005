<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          :show="$apollo.loading"
          spinner-variant="primary"
        >
          <b-card
            class="text-center"
          >
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                Manage
              </h3>
            </b-card-header>
            <b-tabs
              nav-class="mb-3"
              pills
            >
              <b-tab
                active
                class="text-left"
                lazy
                title="Activities"
              >
                <Activities />
              </b-tab>
              <b-tab
                active
                class="text-left"
                lazy
                title="Review"
              >
                <Review />
              </b-tab>
              <b-tab
                class="text-left"
                lazy
                title="Progress"
              >
                <Progress />
              </b-tab>

              <b-tab
                title="Knowledge Base"
                lazy
              >
                <KnowledgeBase />
              </b-tab>
              <b-tab
                title="Video Conferencing"
                lazy
              >
                <VideoConferencing />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  // BCardText,
  // BCardTitle,
  BCol,
  BRow,
  BTab,
  BTabs,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import Calendar from '@/views/programs/programManager/components/calendar/Calendar.vue'
// import { reactive } from '@vue/composition-api'
// import gql from 'graphql-tag'
import Activities from './Activities.vue'
import Review from './Review.vue'
import Progress from './Progress.vue'
import KnowledgeBase from './KnowledgeBase.vue'
import VideoConferencing from './VideoConferencing.vue'

export default {
  components: {
    Activities,
    Review,
    Progress,
    KnowledgeBase,
    VideoConferencing,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    // BCardText,
    // BCardTitle,
    BButton,
    // Calendar,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // program: '',
      // applicationData: '',
      // participantData: '',
      // phaseData: '',
      // subPhaseData: '',
      // operationId: [],
      // activityData: '',
      // subPhaseId: [],

      // pvent: document.createEvent('Pvent'),
      // test: [{ operations_id: { _eq: 11 } }, { operations_id: { _eq: 74 } }],
    }
  },
  setup() {
  },
  computed: {
  },
  watch: {
  },
  apollo: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
