<template>
  <div />
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style>

</style>
