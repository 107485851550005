<template>
  <div>
    <b-card
      class="mt-2"
    >
      <b-card-title class="lead">
        Activities
      </b-card-title>
      <calendar />
    </b-card>
  </div>
</template>
<script>
import Calendar from '@/views/programs/programManager/components/calendar/Calendar.vue'
import gql from 'graphql-tag'
import {
  BCard,
  BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    Calendar,
  },
  data() {
    return {
      dateValues: {
        begin_date: '',
        buffer_begin_date: '',
        buffer_end_date: '',
        end_date: '',
      },
      scheduler: {
        programSchedule: null,
        bufferSchedule: null,
      },
    }
  },
  methods: {
    addDate() {
      this.dateValues.begin_date = this.scheduler.programSchedule.slice(0, 10)
      this.dateValues.end_date = this.scheduler.programSchedule.slice(14, 24)
      this.dateValues.buffer_begin_date = this.scheduler.bufferSchedule.slice(0, 10)
      this.dateValues.buffer_end_date = this.scheduler.bufferSchedule.slice(14, 24)
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation(
          $dateInput: programs_basicinfo_set_input!
        ) {
        update_programs_basicinfo(where: {id: {_eq: ${this.$route.params.id}}},
          _set: $dateInput) {
          affected_rows
          returning {
            id
          }
        }
      }`,
        variables: {
          dateInput: this.dateValues,
        },
      })
    },
  },
}
</script>
